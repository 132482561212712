import { companyConfig } from "../../configs/company-config";
import styles from "./heroTitle.module.css";

const HeroTitle = () => {
    return (
        <header className={styles.heroTitleContainer}>
            <h1 className={styles.heroTitleTop}>{companyConfig.name}</h1>
            <div className={styles.divider}></div>
            <span className={styles.heroTitleBottom}>Mediation & Arbitration Services</span>
        </header>
    )
}

export default HeroTitle;